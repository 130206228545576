const routes = [
  {
    path: ["/", "/magicbricks", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/tractorcard"],
    exact: true,
    component: "Tractor",
  },
];

export default routes;
