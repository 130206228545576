import { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

import routes from "./config";
import GlobalStyles from "../globalStyles";

const reload = () => window.location.reload();
const Router = () => {
  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      <Header />
      <Switch>
        <Route path="/privacy_policy.html" render={reload} />
        <Route path="/privacy" render={() => <Redirect
          to={{
            pathname: "/privacy_policy.html"
          }}
        />}
        />
        {/* Reroute home to MagicBricks for now */}
        {/* <Route path="/" render={() => <Redirect
          to={{
            pathname: "/home"
          }}
        />}
        /> */}
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
