import { lazy, Fragment } from "react";
import { Row, Col, Divider } from "antd";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Container = lazy(() => import("../../common/Container"));

const Footer = ({ t }) => {
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <Fragment>
      <Fade bottom>
        <S.Footer>
          <Container>
            <Row type="flex" justify="center" aligh="middle">
              <S.CenterContentCol lg={12} md={12} sm={12} xs={24}>
                <a href="mailto:info@littlemyles.com">
                  <S.Large to="mailto:info@littlemyles.com">{t("Contact us")}</S.Large>
                </a>
                <S.ColorDivider type="vertical"/>
                <a href="/privacy" target="_blank">
                  <S.Large>
                    {t("Privacy Policy ")}
                  </S.Large>
                </a>
              </S.CenterContentCol>
            </Row>
            <Row type="flex" justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <S.CopyRightPara>
                  {t(
                    `Copyright © 2021 LittleMyles LLC. All Rights Reserved`
                  )}
                </S.CopyRightPara>
              </Col>
            </Row>
          </Container>
        </S.Footer>
      </Fade>
    </Fragment>
  );
};

export default withTranslation()(Footer);
